@import '~ethos-design-system/src/components/Media/Media.scss';
@import '~ethos-design-system/src/components/TypeBase/TypeBase.scss';

.body {
  padding-bottom: var(--Space-72);
}

.headings {
  @include for-phone-only {
    padding: var(--Space-48) 0;
  }

  @include for-tablet-only {
    padding: var(--Space-40) 0;
  }

  @include for-laptop-and-up {
    padding: var(--Space-72) 0;
  }
}

.content {
  display: flex;

  // This is a sidebar that should only be displayed on larger screens.
  aside {
    display: none;

    @include for-laptop-and-up {
      display: inline-block;
      width: 320px;
      flex: 0 0 320px;
      margin-right: var(--Space-80);
    }

    nav {
      > * {
        // may need to redo once linkified
        padding-bottom: var(--Space-24);
      }

      a {
        color: var(--GraySecondary--translucent);
        text-decoration: none;
        span {
          padding-right: var(--Space-4);
        }
        &:active,
        &:hover {
          text-decoration: underline;
          color: var(--GrayPrimary--opaque);
        }
      }
    }
  }
}

.group {
  &:not(:last-child) {
    margin-bottom: var(--Space-56);
    @include for-desktop-only {
      margin-bottom: var(--Space-72);
    }
  }

  h3 ~ * {
    margin-top: var(--Space-16);
  }

  p {
    $font-size: 19;
    $line-height: 24;
    font-size: $font-size * 1px;
    line-height: $line-height / $font-size;
  }
}
